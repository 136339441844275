import { options } from 'less';
import moment from 'moment'

// 计算出时间戳的具体数据：比如将85400转化为 n天n时n分n秒
export function formateTimeStamp (timeStamp) {
    var day;
    var hour;
    var min;
    var seconds;

    day = parseInt(timeStamp / (24 * 60 * 60)) // 计算整数天数
    var afterDay = timeStamp - day * 24 * 60 * 60 // 取得算出天数后剩余的秒数
    hour = parseInt(afterDay / (60 * 60)) // 计算整数小时数
    var afterHour = timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 // 取得算出小时数后剩余的秒数
    min = parseInt(afterHour / 60) // 计算整数分
    seconds = parseInt(timeStamp - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60) // 取得算出分后剩余的秒数

    if (day < 10) {
      day = '0' + day;
    }

    if (hour < 10) {
      hour = '0' + hour
    };

    if (min < 10) {
      min = '0' + min;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    const restStamp = {
      day: day,
      hour: hour,
      min: min,
      seconds: seconds
    }
    return restStamp
  }

// 将时间戳转化为时间格式 2020-11-11 00:00:00
export function formData(data) {
  if(data){
    let time = moment(data).format("YYYY-MM-DD HH:mm:ss");
    return time;
  }else{
    return ''
  }
}

// 封装下拉选择器的options格式
// options: [
//   {
//     label: 'a',
//     value: '0'
//   }
// ]
export function selectFormatData(data, key, val) {
  const obj = []
    for (const i in data) {
      const item = data[i]
      obj[i] = {
        value: item[key] || '',
        label: item[val] || ''
      }
    }
    return obj
}

// 在select下拉选择器中，根据指定value返回label，用于表格展示
export function getLabelBySelectValue(data, val) {
  let label = ''
    for (const i in data) {
      const item = data[i]
      if(item.value == val){
        label = item.label
        break
      }
    }
    return label
}
