<template>
  <d2-container>
    <div class="add_ys">
      <!-- 新增医书 -->
      <el-card v-if="type == 'YS'" style="width: 80%; margin: 0 auto">
        <div slot="header" style="text-align:center;">
          <span>{{ titleStrOne }}{{ titleStrTwo }}</span>
        </div>
        <el-form
          ref="formYsRef"
          label-width="80px"
          :model="form_ys"
          :rules="rules_ys"
        >
          <el-form-item label="书籍名称" prop="bookName">
            <el-input v-model="form_ys.bookName"></el-input>
          </el-form-item>
          <el-form-item label="所属分类" prop="type">
            <el-select v-model="form_ys.type">
              <el-option
                v-for="item in types_ys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作者名称" prop="bookAuthor">
            <el-input v-model="form_ys.bookAuthor"></el-input>
          </el-form-item>
          <el-form-item label="书籍简介" prop="bookDepict">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ys.bookDepict"
            ></el-input>
          </el-form-item>
          <el-form-item label="书籍封面" prop="bookCover">
            <el-upload
              class="avatar-uploader"
              action=""
              accept=".jpg, .png"
              :http-request="
                e => {
                  upload(e, 'bookCover');
                }
              "
              :show-file-list="false"
              :before-upload="beforeCoverUpload"
            >
              <img
                v-if="form_ys.bookCover"
                :src="form_ys.bookCover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="章节数量" prop="chapterNum">
            <el-input
              type="number"
              v-model.number="form_ys.chapterNum"
            ></el-input>
          </el-form-item>
          <el-form-item label="书籍字数" prop="bookNum">
            <el-input type="text" v-model="form_ys.bookNum"></el-input>
          </el-form-item>
          <el-form-item label="上传文件" prop="bookFile">
            <el-upload
              ref="uploadFileRef"
              action=""
              accept=".txt"
              :limit="1"
              :http-request="
                e => {
                  upload(e, 'bookFile');
                }
              "
              :before-upload="beforeTxtUpload"
              :show-file-list="true"
              :file-list="form_ys.bookFile"
              :on-remove="onFileRemove"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                仅限txt格式，文件大小不超过300mb
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <div class="footer_btn">
              <el-button type="primary" @click="submitForm('formYsRef')"
                >确认</el-button
              >
              <el-button @click="back">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-card>

      <!-- 新增医案 -->
      <el-card v-if="type == 'YA'" style="width: 80%; margin: 0 auto">
        <div slot="header" style="text-align:center;">
          <span>{{ titleStrOne }}{{ titleStrTwo }}</span>
        </div>
        <el-form
          ref="formYaRef"
          label-width="80px"
          :model="form_ya"
          :rules="rules_ya"
        >
          <el-form-item label="医案名称" prop="caseName">
            <el-input v-model="form_ya.caseName"></el-input>
          </el-form-item>

          <div class="form_sub_title">医师介绍</div>
          <el-form-item label="医师名称" prop="doctorName">
            <el-input v-model="form_ya.doctorName"></el-input>
          </el-form-item>
          <el-form-item label="医师简介" prop="doctorDepict">
            <el-input v-model="form_ya.doctorDepict"></el-input>
          </el-form-item>
          <el-form-item label="研究方向" prop="research">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.research"
            ></el-input>
          </el-form-item>
          <el-form-item label="人物著作" prop="opus">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.opus"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">患者信息</div>
          <el-form-item label="患者姓名" prop="patientName">
            <el-input v-model="form_ya.patientName"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model.number="form_ya.sex">
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input type="number" v-model="form_ya.age"></el-input>
          </el-form-item>

          <div class="form_sub_title">辨证立法</div>
          <el-form-item label="所属分类" prop="type">
            <el-select v-model="form_ya.type">
              <el-option
                v-for="item in types_ya"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记录时间" prop="recordTime">
            <el-input v-model="form_ya.recordTime"></el-input>
          </el-form-item>
          <el-form-item label="主诉" prop="complaint">
            <el-input v-model="form_ya.complaint"></el-input>
          </el-form-item>
          <el-form-item label="现病史" prop="history">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.history"
            ></el-input>
          </el-form-item>
          <el-form-item label="辩证" prop="dialectical">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.dialectical"
            ></el-input>
          </el-form-item>
          <el-form-item label="治则" prop="rulesOfTreatment">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.rulesOfTreatment"
            ></el-input>
          </el-form-item>
          <el-form-item label="方药" prop="prescription">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.prescription"
            ></el-input>
          </el-form-item>
          <template v-for="(item, index) in wenZhensForm">
            <el-form-item
              :key="item.prop"
              :label="item.label"
              :prop="item.prop"
            >
              <div class="wenZhen_box">
                <el-input
                  class="wenZhen_input"
                  type="textarea"
                  :rows="4"
                  maxlength="1000"
                  show-word-limit
                  v-model="item.value"
                ></el-input>
                <div class="wenZhen_add_btn">
                  <el-button
                    v-if="index == wenZhensForm.length - 1"
                    type="primary"
                    size="small"
                    plain
                    @click="addWenZhen(index)"
                    >添加</el-button
                  >
                  <el-button
                    v-if="index == wenZhensForm.length - 1 && index != 0"
                    type="danger"
                    size="small"
                    plain
                    @click="delWenZhen(index)"
                    >删除</el-button
                  >
                </div>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="按" prop="an">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_ya.an"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="footer_btn">
              <el-button type="primary" @click="submitForm('formYaRef')"
                >确认</el-button
              >
              <el-button @click="back">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-card>

      <!-- 新增医药 -->
      <el-card v-if="type == 'YY'" style="width: 80%; margin: 0 auto">
        <div slot="header" style="text-align:center;">
          <span>{{ titleStrOne }}{{ titleStrTwo }}</span>
        </div>
        <el-form
          ref="formYyRef"
          label-width="80px"
          :model="form_yy"
          :rules="rules_yy"
        >
          <div class="form_sub_title">基本信息</div>
          <el-form-item label="药材名称" prop="drugsName">
            <el-input v-model="form_yy.drugsName"></el-input>
          </el-form-item>
          <el-form-item label="所属分类" prop="typeOneAndTwo">
            <el-cascader
              clearable
              v-model="form_yy.typeOneAndTwo"
              :options="types_yy"
              :props="{
                checkStrictly: 'false',
                value: 'id',
                label: 'name',
                children: 'children'
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="拼音" prop="pinYin">
            <el-input v-model="form_yy.pinYin"></el-input>
          </el-form-item>
          <el-form-item label="始载于" prop="origin">
            <el-input v-model="form_yy.origin"></el-input>
          </el-form-item>
          <el-form-item label="别名" prop="alias">
            <el-input v-model="form_yy.alias"></el-input>
          </el-form-item>
          <el-form-item label="性味归经" prop="taste">
            <el-input v-model="form_yy.taste"></el-input>
          </el-form-item>
          <el-form-item label="功效" prop="effect">
            <el-input v-model="form_yy.effect"></el-input>
          </el-form-item>
          <el-form-item label="主治" prop="attending">
            <el-input v-model="form_yy.attending"></el-input>
          </el-form-item>
          <el-form-item label="药材封面" prop="drugsCover">
            <el-upload
              class="avatar-uploader"
              action=""
              accept=".jpg, .png"
              :http-request="
                e => {
                  upload(e, 'drugsCover');
                }
              "
              :show-file-list="false"
              :before-upload="beforeCoverUpload"
            >
              <img
                v-if="form_yy.drugsCover"
                :src="form_yy.drugsCover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="药材简介" prop="drugsInfo">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.drugsInfo"
            ></el-input>
          </el-form-item>
          <el-form-item label="用法用量" prop="dosage">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.dosage"
            ></el-input>
          </el-form-item>
          <el-form-item label="注意事项" prop="matters">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.matters"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">应用</div>
          <el-form-item label="临床运用" prop="clinical">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.clinical"
            ></el-input>
          </el-form-item>
          <el-form-item label="相关配伍" prop="compatibility">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.compatibility"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">炮制</div>
          <el-form-item label="现代炮制" prop="modernProcessing">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.modernProcessing"
            ></el-input>
          </el-form-item>
          <el-form-item label="古代炮制" prop="ancientProcessing">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.ancientProcessing"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">性状</div>
          <el-form-item label="性状" prop="disposition">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.disposition"
            ></el-input>
          </el-form-item>
          <el-form-item label="鉴别" prop="identify">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.identify"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">各家论述</div>
          <el-form-item label="各家论述" prop="discussions">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.discussions"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">药籍摘要</div>
          <el-form-item label="药籍摘要" prop="drugsRecord">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.drugsRecord"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">分布</div>
          <el-form-item label="分布区域" prop="distributionArea">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.distributionArea"
            ></el-input>
          </el-form-item>
          <el-form-item label="道地产区" prop="daoRealEstate">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.daoRealEstate"
            ></el-input>
          </el-form-item>
          <el-form-item label="生长环境" prop="growthEnvironment">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.growthEnvironment"
            ></el-input>
          </el-form-item>

          <div class="form_sub_title">药理研究</div>
          <el-form-item label="化学成分" prop="chemicalComposition">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.chemicalComposition"
            ></el-input>
          </el-form-item>
          <el-form-item label="药理作用:" prop="pharmacologicalAction">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              show-word-limit
              v-model="form_yy.pharmacologicalAction"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="footer_btn">
              <el-button type="primary" @click="submitForm('formYyRef')"
                >确认</el-button
              >
              <el-button @click="back">返回</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </d2-container>
</template>

<script>
// 引入接口
import KnowledgeBaseApi from "@/model/modules/modCms_konwledgeBase/index";
import { uploadPics } from "@/model/modules/user";

// 引入方法
import { selectFormatData } from "@/utils/tool";

export default {
  data() {
    return {
      titleStrOne: "",
      titleStrTwo: "",
      type: "",
      id: "",
      wenZhens_length: 1,
      wenZhensForm: [],
      wenZhens: [
        { label: "二诊", prop: "twoZhen", value: "" },
        { label: "三诊", prop: "threeZhen", value: "" },
        { label: "四诊", prop: "foreZhen", value: "" },
        { label: "五诊", prop: "fiveZhen", value: "" },
        { label: "六诊", prop: "sixZhen", value: "" },
        { label: "七诊", prop: "sevenZhen", value: "" },
        { label: "八诊", prop: "eightZhen", value: "" },
        { label: "九诊", prop: "nineZhen", value: "" },
        { label: "十诊", prop: "tenZhen", value: "" },
        { label: "十一诊", prop: "elevenZhen", value: "" }
      ],

      form_ys: {
        bookName: "",
        type: "",
        bookAuthor: "",
        bookDepict: "",
        bookCover: "",
        chapterNum: "",
        bookNum: "",
        bookFile: []
      },
      form_ya: {
        caseName: "",
        doctorName: "",
        doctorDepict: "",
        research: "",
        opus: "",
        patientName: "",
        sex: "",
        age: "",
        type: "",
        recordTime: "",
        complaint: "",
        history: "",
        dialectical: "",
        rulesOfTreatment: "",
        prescription: "",
        twoZhen: "",
        an: ""
      },
      form_yy: {
        drugsName: "",
        typeOneAndTwo: "",
        pinYin: "",
        origin: "",
        alias: '',
        taste: "",
        effect: "",
        attending: "",
        drugsCover: "",
        drugsInfo: "",
        dosage: "",
        matters: "",
        clinical: "",
        compatibility: "",
        modernProcessing: "",
        ancientProcessing: "",
        disposition: "",
        identify: "",
        discussions: "",
        history: "",
        drugsRecord: "",
        distributionArea: '',
        daoRealEstate: "",
        growthEnvironment: "",
        chemicalComposition: "",
        pharmacologicalAction: ""
      },

      rules_ys: {
        bookName: [
          { required: true, message: "请输入书籍名称", trigger: "blur" }
        ],
        type: [
          { required: true, message: "请选择所属分类", trigger: "change" }
        ],
        bookAuthor: [
          { required: true, message: "请输入作者名称", trigger: "blur" }
        ],
        bookDepict: [
          { required: true, message: "请输入书籍简介", trigger: "blur" }
        ],
        bookCover: [
          { required: true, message: "请选择书籍封面", trigger: "change" }
        ],
        chapterNum: [
          { required: true, message: "请输入章节数量", trigger: "blur" }
        ],
        bookNum: [
          { required: true, message: "请输入书籍字数", trigger: "blur" }
        ],
        bookFile: [
          { required: true, message: "请选择上传文件", trigger: "change" }
        ]
      },
      rules_ya: {
        caseName: [
          { required: true, message: "请输入医案名称", trigger: "blur" }
        ],
        doctorName: [
          { required: true, message: "请输入医师名称", trigger: "blur" }
        ],
        doctorDepict: [
          { required: true, message: "请输入医师简介", trigger: "blur" }
        ],
        research: [
          { required: true, message: "请输入研究方向", trigger: "blur" }
        ],
        opus: [{ required: true, message: "请输入人物著作", trigger: "blur" }],
        patientName: [
          { required: true, message: "请输入患者姓名", trigger: "blur" }
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        type: [
          { required: true, message: "请选择所属分类", trigger: "change" }
        ],
        recordTime: [
          { required: true, message: "请输入记录时间", trigger: "blur" }
        ],
        complaint: [{ required: true, message: "请输入主诉", trigger: "blur" }],
        history: [{ required: true, message: "请输入现病史", trigger: "blur" }],
        dialectical: [
          { required: true, message: "请输入辩证", trigger: "blur" }
        ],
        rulesOfTreatment: [
          { required: true, message: "请输入治则", trigger: "blur" }
        ],
        prescription: [
          { required: true, message: "请输入方药", trigger: "blur" }
        ],
        twoZhen: "",
        an: [{ required: true, message: "请输入按", trigger: "blur" }]
      },
      rules_yy: {
        drugsName: [
          { required: true, message: "请输入药材名称", trigger: "blur" }
        ],
        typeOneAndTwo: [
          { required: true, message: "请选择所属分类", trigger: "change" }
        ],
        pinYin: [{ required: true, message: "请输入拼音", trigger: "blur" }],
        taste: [{ required: true, message: "请输入性味归经", trigger: "blur" }],
        effect: [{ required: true, message: "请输入功效", trigger: "blur" }],
        attending: [{ required: true, message: "请输入主治", trigger: "blur" }],
        drugsCover: [
          { required: true, message: "请选择药材封面", trigger: "change" }
        ],
        dosage: [
          { required: true, message: "请输入药材简介", trigger: "blur" }
        ],
        drugsInfo: [
          { required: true, message: "请输入用法用量", trigger: "blur" }
        ],
        matters: [
          { required: true, message: "请输入注意事项", trigger: "blur" }
        ]
      },

      types_ys: [],
      types_ya: [],
      types_yy: []
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      if (this.type == "YS") {
        this.titleStrTwo = "医书";
      } else if (this.type == "YA") {
        this.titleStrTwo = "医案";
      } else if (this.type == "YY") {
        this.titleStrTwo = "医药";
      }
    }

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.titleStrOne = "修改";
      this.getDetailById();
    } else {
      this.titleStrOne = "新增";
      this.wenZhensForm[0] = this.wenZhens[0];
    }

    this.getTypes();
  },
  methods: {
    // 获取分类
    async getTypes() {
      if (this.type == "YS") {
        let types_ys = await KnowledgeBaseApi.getDicListByCateCode("BOOK_TYPE");
        if (types_ys.data.code == 0) {
          this.types_ys = selectFormatData(
            types_ys.data.body.content,
            "id",
            "itemName"
          );
        } else {
          console.log(types_ys.data.msg);
        }
      } else if (this.type == "YA") {
        let types_ya = await KnowledgeBaseApi.getDicListByCateCode(
          "MEDICAL_CASE_TYPE "
        );
        if (types_ya.data.code == 0) {
          this.types_ya = selectFormatData(
            types_ya.data.body.content,
            "id",
            "itemName"
          );
        } else {
          console.log(types_ya.data.msg);
        }
      } else if (this.type == "YY") {
        let types_yy = await KnowledgeBaseApi.getDrugsCateTrees();
        if (types_yy.data.code == 0) {
          this.types_yy = types_yy.data.body;
        } else {
          console.log(types_yy.data.msg);
        }
      }
    },

    // 获取详情
    getDetailById() {
      let params = {
        id: this.id,
        type: this.type
      };
      KnowledgeBaseApi.getDetailById(params).then(res => {
        if (res.data.code == 0) {
          if (this.type == "YS") {
            res.data.body.bookFile = [
              {
                name: res.data.body.bookName,
                url: res.data.body.bookFile
              }
            ];
            this.form_ys = res.data.body;
          } else if (this.type == "YA") {
            this.form_ya = res.data.body;
            this.$nextTick(() => {
              // 判断有几条问诊记录
              this.wenZhensForm.push({
                label: "二诊",
                prop: "twoZhen",
                value: this.form_ya.twoZhen
              });

              if (this.form_ya.threeZhen) {
                this.wenZhensForm.push({
                  label: "三诊",
                  prop: "threeZhen",
                  value: this.form_ya.threeZhen
                });
              }
              if (this.form_ya.foreZhen) {
                this.wenZhensForm.push({
                  label: "四诊",
                  prop: "foreZhen",
                  value: this.form_ya.foreZhen
                });
              }
              if (this.form_ya.fiveZhen) {
                this.wenZhensForm.push({
                  label: "五诊",
                  prop: "fiveZhen",
                  value: this.form_ya.fiveZhen
                });
              }
              if (this.form_ya.sixZhen) {
                this.wenZhensForm.push({
                  label: "六诊",
                  prop: "sixZhen",
                  value: this.form_ya.sixZhen
                });
              }
              if (this.form_ya.sevenZhen) {
                this.wenZhensForm.push({
                  label: "七诊",
                  prop: "sevenZhen",
                  value: this.form_ya.sevenZhen
                });
              }
              if (this.form_ya.eightZhened) {
                this.wenZhensForm.push({
                  label: "八诊",
                  prop: "eightZhen",
                  value: this.form_ya.eightZhen
                });
              }
              if (this.form_ya.nineZhen) {
                this.wenZhensForm.push({
                  label: "九诊",
                  prop: "nineZhen",
                  value: this.form_ya.nineZhen
                });
              }
              if (this.form_ya.tenZhen) {
                this.wenZhensForm.push({
                  label: "十诊",
                  prop: "tenZhen",
                  value: this.form_ya.tenZhen
                });
              }
              if (this.form_ya.elevenZhen) {
                this.wenZhensForm.push({
                  label: "十一诊",
                  prop: "elevenZhen",
                  value: this.form_ya.elevenZhen
                });
              }
            });
          } else if (this.type == "YY") {
            res.data.body.typeOneAndTwo = [
              res.data.body.typeOne,
              res.data.body.typeTwo
            ];
            this.form_yy = res.data.body;
          }
        }
      });
    },

    // 上传文件 移除时触发
    onFileRemove(file, fileList) {
      this.form_ys.bookFile = fileList;
    },

    // 上传文件到服务器
    upload(e, prop) {
      const formdata = new FormData();
      formdata.append("file", e.file);

      var loading = this.$loading({
        lock: true,
        text: "上传中"
      });

      uploadPics(formdata)
        .then(res => {
          if (res.data.code == 0) {
            if (this.type == "YS") {
              // 如果上传文件，则文件名和url，用于展示
              if (prop == "bookFile") {
                this.form_ys.bookFile[0] = {
                  name: e.file.name,
                  url: res.data.body[0].url
                };
              } else {
                this.form_ys[prop] = res.data.body[0].url;
              }
            } else if (this.type == "YA") {
              this.form_ya[prop] = res.data.body[0].url;
            } else if (this.type == "YY") {
              this.form_yy[prop] = res.data.body[0].url;
            }

            this.$message({
              type: "success",
              message: "上传成功",
              duration: 1500
            });
          } else {
            this.$message({
              type: "error",
              message: "上传失败",
              duration: 1500
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },

    // 上传图片前 触发事件
    beforeCoverUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }
      // else {
      //   this.upload_file = new FormData()
      //   this.upload_file.append('file', file)
      // }
    },

    // 上传文件前 触发事件
    beforeTxtUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 300;

      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 300MB!");
        return false;
      }
    },

    // 表单提交事件
    submitForm(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          if (this.type == "YS") {
            let form = { ...this.form_ys };
            form.bookFile = form.bookFile[0].url;
            this.add(form, name);
          } else if (this.type == "YA") {
            this.wenZhensForm.forEach(item => {
              this.form_ya[item.prop] = item.value;
            });
            this.add(this.form_ya, name);
          } else if ((this.form_yy, name)) {
            if (this.form_yy.typeOneAndTwo.length == 1) {
              this.form_yy.typeOne = this.form_yy.typeOneAndTwo[0];
              this.form_yy.typeTwo = "";
            } else if (this.form_yy.typeOneAndTwo.length == 2) {
              this.form_yy.typeOne = this.form_yy.typeOneAndTwo[0];
              this.form_yy.typeTwo = this.form_yy.typeOneAndTwo[1];
            }

            this.add(this.form_yy, name);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 新增医书
    add(form, formName) {
      const loading = this.$loading()

      KnowledgeBaseApi.add(form, this.type).then(res => {
        if (res.data.code == 0) {
          loading.close()
          let str = this.titleStrOne + "成功";
          this.$message.success(str);

          // 修改成功
          if (this.id) {
            this.back();
          } else {
            // 新增成功
            this.wenZhensForm = [];
            this.$refs[formName].resetFields();
          }
        }
      });
    },

    // 返回
    back() {
      this.$router.go(-1);
    },

    // 添加问诊
    addWenZhen(index) {
      if (this.wenZhensForm.length >= 10) {
        this.$message.error("只能添加到十一诊");
        return false;
      }
      this.wenZhensForm.push({ ...this.wenZhens[index + 1], value: "" });
    },

    // 删除问诊
    delWenZhen(index) {
      this.form_ya[this.wenZhensForm[index].prop] = "";
      this.wenZhensForm.splice(index, 1);
      console.log(this.wenZhensForm);
    }
  }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.footer_btn {
  float: right;
}

.form_sub_title {
  font-weight: bolder;
  margin-bottom: 10px;
  font-size: 18px;
}

.wenZhen_box {
  display: flex;
  align-items: center;
}

.wenZhen_add_btn {
  flex: 1;
  text-align: center;
}

.wenZhen_input {
  flex: 6;
}
</style>
