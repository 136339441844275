import { deletes, post, get } from "../../service";

export default class KnowledgeBaseApi {
  // 字典表
  static getDicListByCateCode(cateCode = undefined) {
    let params = {
      cateCode: cateCode,
      pageIndexB1: 1,
      pageSize: 50
    };
    return post("/service/Dict/listByCateCode", params);
  }

  // 医药分类树
  static getDrugsCateTrees(params = undefined) {
    return get("/service/app/knowledge/drugs/drugsCateTrees", params);
  }

  // 详情
  static getDetailById(params = undefined) {
    let url = "";
    if (params.type == "YS") {
      url = "/service/knowledge/manage/getBookInfo/" + params.id;
    } else if (params.type == "YA") {
      url = "/service/knowledge/manage/getCaseInfo/" + params.id;
    } else if (params.type == "YY") {
      url = "/service/knowledge/manage/getDrugsInfo/" + params.id;
    }
    return get(url, params);
  }

  // 删除
  static deleteRow(params = undefined) {
    if (params.type == "YS") {
      return deletes("/service/knowledge/manage/deleteBook/" + params.id)
    } else if(params.type == 'YA'){
      return deletes("/service/knowledge/manage/deleteCase/" + params.id)
    } else if(params.type == 'YY'){
      return get('/service/knowledge/manage/deleteDrugs/'+params.id)
    }
  }

  // 新增
  static add(params = undefined, type = undefined) {
    let url = "";
    if (type == "YS") {
      url = "/service/knowledge/manage/saveBook";
    } else if (type == "YA") {
      url = "/service/knowledge/manage/saveCase";
    } else if (type == "YY") {
      url = "/service/knowledge/manage/saveDrugs";
    }
    return post(url, params);
  }

  static pageList(params = undefined) {
    return post("/service/knowledge/manage/getList", params);
  }

  // 修改权重
  static changeWeight(params = undefined) {
    let url = "";
    if (params.type == "YS") {
      url = "/service/knowledge/manage/updateBookList/";
    } else if (params.type == "YA") {
      url = "/service/knowledge/manage/updateCaseList/";
    } else if (params.type == "YY") {
      url = "/service/knowledge/manage/updateDrugsList/";
    }
    return get(url + params.weight+'/'+params.id)
  }
}
